import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          subtitle: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const MAX_LENGTH = 250

  return (
    <Layout location={location} title={siteTitle}>
    <SEO title="All posts" />
      <header className="mastheadBlog">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
              <h1>Cannab Blogs</h1>
            </div>
          </div>
        </div>
      </header>
      <section className="container">
      <div className="row mt-5 mb-5">
        <div className="col col-md-12">
          <h4 className="blogIntro">Our Articles, Putting it Bluntly.</h4>
        </div>
      </div>          
      <div className="row">
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug

        var bannerBg = {
          backgroundImage: `url(${node.frontmatter.image.publicURL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        };
      
        return (

          
          <article key={node.fields.slug} className="col-lg-4 col-md-6 text-center mb-5">
              <div className="featured-text text-lg-left">
                <Link to={node.fields.slug} className="blog-image-container" style={ bannerBg } >&nbsp;</Link>
                <h5>{title}</h5>
                <h6>{node.frontmatter.subtitle}</h6>
                <time>{node.frontmatter.date}</time>
                <p dangerouslySetInnerHTML={{ __html: node.frontmatter.description || node.excerpt, }} />
                <Link to={node.fields.slug} className="btn btn-outline-secondary">Read Article</Link>
              </div>
          </article>
        )
      })}
      </div>
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  
query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            subtitle
            description
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`